import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import loginin from "../../assets/img/logowhite.png";

export const Register = () => {
  const secret_key = "6LfkXSElAAAAAA0oAkMb9ZHeQte2vD6iHDJlhq_C";
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const captcha = useRef(null);

  const onChange = (value) => {
    setRecaptchaValue(value);
    if (captcha.current.getValue()) {
      console.log("El usuario no es un robot");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!recaptchaValue) {
      alert("Debes verificar que no eres un robot");
      return;
    }

    if (inputValue == "") {
      alert("Ingresa un dato");
      return;
    }
    window.location.href = "/certificados/" + inputValue;
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <div class=" contenedor">
        <div class="login-box centrar">
          <p>
            Verifique la validez de su certificado de cursos, diplomados y
            actividades académicas que no requieran título profesional de forma
            sencilla
          </p>
          <p>
            Usted podrá realizar su consulta por Identificación, posteriormente
            haga clic en consultar, el sistema lo llevará al certificado
            digital.
          </p>
          <p>El campo marcado con * es requerido.</p>
          <div>
            <p>¡Información!</p>
            <em>
              Señor(a) Estudiante. Si presenta algún inconveniente o duda frente
              a este tema, por favor escribir a: contacto@iudigital.edu.co.
            </em>
          </div>
          <form className="formulario" onSubmit={handleSubmit}>
            {/*<img src={loginin} className="avatar" alt="Avatar Image" />
             */}

            <div className="text-center">
              <label className="label" for="">
                Ingrese el número de su documento de identidad para descargar el
                certificado.
              </label>
              <input
                className=""
                id="identificacion"
                type="text"
                placeholder="Documento de identidad *"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
            <div className="recaptcha">
              <ReCAPTCHA
                ref={captcha}
                sitekey={secret_key}
                onChange={onChange}
              />
            </div>
            <div>
              <button type="submit">Consultar</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
