import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  useNavigate,
  useParams,
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
//import Loading from '../certificados/Loading';

import "../../components/css/Loading.css";
import { Spinner } from "reactstrap";

export const Certificados = () => {
  const { identificacion } = useParams();
  const documento = identificacion;
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState("divPadre");
  const navigate = useNavigate();

  const style = {
    hoverRow: {
      backgroundColor: "blue",
    },
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}certificados_estudiante/${documento}`
      )
      .then((response) => {
        console.log("a");
        setLoading("divPadre");
        if (response.data.code == 404) {
          alert("Documento " + documento + " no se encuentra registrado");
          window.location.href = "/register";
        } else {
          if (
            Array.isArray(response.data.data.certificados) &&
            response.data.data.certificados.length === 0
          ) {
            alert(
              "Documento " + documento + " no tiene certificados asociados"
            );
            window.location.href = "/register";
          } else {
            setLista(response.data.data.certificados);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        alert("¡Ups! Algo salió mal. Por favor, contacta a soportecampus@iudigital.edu.co");
        window.location.href = "/register";
      });
  }, []);

  
  const getPdf = async (certificadoId) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}estudiante/certificado/generateCertificate/${certificadoId}`,
        { responseType: "blob" }
      )
      .then((response) => {
        let fileName = response.headers
          .get("Content-Disposition")
          .split("filename=")[1]
          .replace(/['"]+/g, ""); // Nombre predeterminado

        setLoading("divPadre");
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName; // Utilizar el nombre del archivo obtenido
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
        alert("¡Ups! Algo salió mal. Por favor, contacta a soportecampus@iudigital.edu.co");
      });
  };

  const cambiarEstado = (certificadoId) => {
    setLoading("divPadre activo");
    getPdf(certificadoId);
  };

  const esURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      {/*<div class="d-grid gap-2 d-md-flex justify-content-md-end ">
        <button onClick={() => navigate("/")} class="btn btn-primary me-md-2" type="button">Cerrar sesión</button>
  </div>*/}

      <style>
        {`
          .lista-certificados:hover {
              background-color: #023C58;
          }
          
          .lista-certificados:hover td{
              color: white !important;
          }

          .lista-certificados:hover td svg {
            fill: white; /* Cambia el color del SVG a blanco */
          }

          .lista-certificados:hover td a {
            color: white; /* Cambia el color de los enlaces a blanco */
          }
        `}
      </style>
      <div id="nose"></div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>id Certificado</th>
            <th>Nombre Curso</th>
            <th>Horas</th>
            <th>Fecha</th>
            <th>Encuesta</th>
            <th>Descargar</th>
          </tr>
        </thead>
        <tbody>
          {lista.map((item) => (
            <tr className="lista-certificados">
              <td>{item.certificadoId}</td>
              <td>
                {item.info_curso.nombreCurso} {item?.info_curso?.nombreCurso2}
              </td>
              <td>{item.info_curso.horas}</td>
              <td>{new Date(item.fechaCarga).toLocaleDateString("es-ES")}</td>
              <td className="text-center">
                {esURL(item.info_curso.url_encuesta) ? (
                  <a
                    className="link-encuesta"
                    href={item.info_curso.url_encuesta}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Encuesta
                  </a>
                ) : (
                  "N/A"
                )}
              </td>
              <td
                onClick={() => cambiarEstado(item.certificadoId)}
                className="btn-download d-flex justify-content-center"
                title="Descargar PDF"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  alt="Descargar PDF"
                  height="30"
                  viewBox="0 -960 960 960"
                  width="30"
                >
                  <path d="m720-120 160-160-56-56-64 64v-167h-80v167l-64-64-56 56 160 160ZM560 0v-80h320V0H560ZM240-160q-33 0-56.5-23.5T160-240v-560q0-33 23.5-56.5T240-880h280l240 240v121h-80v-81H480v-200H240v560h240v80H240Zm0-80v-560 560Z" />
                </svg>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className={loading}>
        <div className="divHijo">
          <div class="spinner-grow" role="status"></div>
          <br />
          <p>Estamos creando tu certificado...</p>
        </div>
      </div>
    </div>
  );
};
