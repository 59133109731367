import React from 'react';
import {AppRouter} from './routes/AppRouter'
const  App = () => {
  return (
    <AppRouter />
  );
}

export default App;

