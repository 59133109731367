import React from 'react'
import { Routes, Route, BrowserRouter } from "react-router-dom";

/* Rutas publicas*/
import { Certificados } from '../components/certificados/Certificados';
import { Register } from '../components/register/register';


export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/certificados/:identificacion" element={<Certificados />} />
        <Route path="/register" element={ <Register /> } />      
        <Route path="/" element={ <Register /> } />
      </Routes>
    </BrowserRouter>
  )
}
